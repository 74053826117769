.addEvent{
    color: #3391aa;
    margin-top: 1rem;
    height: 43px !important;
    cursor: pointer;
    width: 39px !important;
}
.exchangesBlock{
    padding: 12px;
    margin: 5px 0px 0px 0px;
    border: 1px solid #dddcdc;
    border-radius: 5px;
    background: #fffdfd;
}

.fileUploaded {
    border:1px solid #AAA;
    margin-top:5px;
    font-size:0.8rem;
    padding:5px;
    border-radius: 5px;
    font-style: italic;
    color: #aaa;
}

.noHover:hover {
    background-color:white;
    color:black;
}