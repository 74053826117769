.accordion-button:focus {
    z-index: 3;
    border-color: #7e8288;
    outline: 0;
    box-shadow: 0 0 0 0.15rem #f0f1f1;
}

.accordion-button:not(.collapsed) {
    color: rgb(80, 80, 80);
    background-color: #fbfbfb; 
    /* box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%); */
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: 1rem 1.25rem;
    font-size: 1rem;
    color: rgb(80, 80, 80);
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out,border-radius .15s ease;
}

.scrollContainer {
    height: 339px;
    max-height: 339px;
}

.tabContent-scroll{
    height: 339px;
    max-height: 339px;
    overflow: auto;
    padding: 7px 10px;
}

.accordion-body{
    font-size:13px;
    color: rgb(66, 65, 65);
}

.searchIcon{
    height: auto !important;
    padding: 5px 5px;
    width: 34px;
    border: 1px solid #ced4da;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    background: #fbfbfb;
    cursor: pointer;
}

.documentation{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    padding: 9px 18px 3px 24px;
    margin-bottom: 7px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.downloadIcon{
    float: right;
    cursor: pointer;
}
.downloadIcon:hover{
    color: rgb(252, 89, 89);
}

.nav-link {
    color:#7e8288;
}

.nav-link:hover {
    color:#515253;
}

.nav-link.active {
    color:black;
    font-weight: bold;
}

