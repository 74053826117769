.css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline {
    border-color: #3391aa !important;
}

.notranslate{
    padding-left: 0px !important;
    padding-right: 0px !important;
    display: inline-block; 
}
.css-1z7n62 {
    float: unset;
    display: none;
    width: none;
    padding: 0;
    height: 11px;
    font-size: 0.75em;
    visibility: hidden;
    max-width: 100%;
    -webkit-transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
    transition: max-width 100ms cubic-bezier(0.0, 0, 0.2, 1) 50ms;
    white-space: nowrap;
}