.modal_w_mc{
    width: fit-content;
}

.suppliersListModal__body{
    min-width: 40rem;
    width: 835px;
}

.suppliersListModal__entities_container{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.suppliersListModal__entities_header{
    width: 100%;
    margin-bottom: 0.5rem;
}

.suppliersListModal__entities_list{
    margin-top: 0.5rem;
    max-height: 600px;
    overflow-y: auto;
    background-color: #ffffff;
}

.suppliersListModal__entities_table{
    width: 800px;
    max-width: 800px;
}

.suppliersListModal__entities_table td {
    width: 100%;
    font-size: 0.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.suppliersListModal__entities_table th {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}