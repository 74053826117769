
.css-1s2u09g-control {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: hsl(0, 0%, 100%);
    border-color: hsl(0, 0%, 80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    cursor: default;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex-wrap: wrap;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    justify-content: space-between;

    outline: 0!important;
    position: relative;
    -webkit-transition: all 100ms;
    transition: all 100ms;
    box-sizing: border-box;
    height: 49px;
    
}
.css-b62m3t-container {
    position: relative;
    box-sizing: border-box;
   MIN-WIDTH: 170PX;
}
.css-qc6sy-singleValue {
    color: hsl(0, 0%, 20%);
    grid-area: 1/1/2/3;
    margin-left: 2px;
    margin-right: 2px;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    box-sizing: border-box;
    font-size: 0.9rem;
    font-weight: bold;}

    .css-1s2u09g-control {
        align-items: center;
        background-color: hsl(0, 0%, 100%);
        border-color: hsl(0, 0%, 80%);
        border-radius: 4px;
        border-style: solid;
        border-width: 1px;
        cursor: default;
        display: flex;
        -webkit-box-flex-wrap: wrap;
        flex-wrap: wrap;
        justify-content: space-between;
        min-height: 38px;
        outline: 0!important;
        position: relative;
        transition: all 100ms;
        box-sizing: border-box;
        height: fit-content !important;
    }
    .css-2613qy-menu{
        font-size: 0.7rem;

    }