.forgotPassword__container{
    overflow: auto;
    height: 100vh;
    background-image: url("../../../assets/images/login_background.jpg");
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.forgotPassword__formContainer {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.smallPlaceholder::placeholder {
    font-size: smaller;
}