.usersMAnag__paginationContainer{
    display: flex;
    align-items: center;
    /* float: right; */
    justify-content: center;
    /* margin-top: 0.5rem; */
}
.usersMAnag__paginationList{
    display: flex;
    align-items: center;
    justify-content: center;
}
.usersMAnag__pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin: 0;
}
.usersMAnag__page_item{
    display: flex;
    justify-content: center;
    align-items: center;
}
.usersMAnag__page_link{
    background-color: #f8f9fa;
    color:#3c3f43;
    padding: 0.25rem 0.5rem;
    font-size: 0.9rem;
    border-radius: 50%;
    margin-right: 0.5rem;
    text-decoration: none;
}

.usersMAnag__page_link:hover {
    transition: all 0.2s ease-in-out;
    background-color: #dae0e5;
    text-decoration: none;
    color: #ffffff;
}
.usersMAnag__disabled .usersMAnag__page_link:hover {
    background-color: #f8f9fa;
    text-decoration: none;
    color: #3c3f43;
    cursor: not-allowed;
}
.usersMAnag__active  .usersMAnag__page_link{
    background-color: #dae0e5;
}

.usersMAnag__pagination__total{
    display: flex;
    justify-self: flex-start;
    font-size: 0.9rem;
}

.selectWithDeleteButton_optionContainer {
    margin: 5px 0;
    cursor: pointer;
    background-color: white;
}

.selectWithDeleteButton_option {
    padding: 0.5rem 0.5rem;
    border-radius: 16px;
    margin: 0;
}

.selectWithDeleteButton_option:hover {
    background-color: #D9E1E7;
}

.selectWithDeleteButton_deleteButton {
    color: red;
    margin: 0.25rem 0.5rem;
}