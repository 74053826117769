.questionCategoryCard_body {
    display: inline-table;
    background-color: #fafaf9;
    border: 1px solid #d9d9d9;
    border-radius: 10px;
    text-align: center;
    width: 9rem;
    height: 7rem;
    cursor: pointer;
    padding: 10px;
    margin: 10px;
    position: relative;
    transition: 0.4s;
}

.questionCategoryCard_body:hover {
    transition: 0.4s;
    background-color: #e9e9e6;
}

.questionCategoryCard_body_selected {
    border-color: red;
}

.questionCategoryCard_label {
    display: block;
    font-weight: bold;
    font-size: 0.8rem;
    margin-top: 2rem;
}

.questionCategoryCard_icon {
    height: 5rem;
    object-fit: contain;
    display: block;
    margin: auto;
}

.questionCategoryCard_deleteIcon {
    position: absolute;
    right: 10px;
}

.questionCategoryCard_deleteIcon svg {
    height: 0.8em;
    width: 0.8em;
}