.addCatBotton {
    color: #2174b9;
    height: auto !important;
    background: aliceblue;
    border: 1px solid lightgray;
    width: 2rem !important;
    cursor: pointer;
}

.categoryLineInput  {
    /* margin:"0";
    font-family: inherit;
                                                    fontSize: "inherit",
                                                    lineHeight: "inherit", */
       width: 90%;
                                           
}
                                                    
                                                   
                                                     



.saveCatBotton {
    color: #2174b9;
    height: auto !important; 
    cursor: pointer;
}

.categoryElm{
    /* border: 1px solid lightgray; */
    margin-bottom: 0.5rem;
    padding: 0px 0px 0px 10px;
    display: flex;
    justify-content: space-between;
}

.categoryElm span{
    font-size:15px
}



.deleteCategory{
    cursor: pointer;
    color: rgb(250, 83, 83)
}