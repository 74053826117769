.importHeader {
    margin:19px 19px 0 19px;
    background-color:red;
    color:white;
    font-size:12px;
    line-height:21px;
    font-weight:bold;
    border-bottom: 1px solid rgb(128, 159, 184);
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.importTableRow {
    border-bottom:1px solid #809FB8 !important;
    position:relative;
}

.importTableLabel {
    width:75% !important;
}

.importTableValue {
    text-align: right !important;
    width:25% !important;
}