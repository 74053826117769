.futureCalendar .rdrDay, .futureCalendar .rdrWeekDay {
    padding: 3.5vh;
    font-size: 18px;
}

.futureCalendar .rdrMonth {
    width: fit-content;
}

.futureCalendar .rdrNextPrevButton {
    padding: 22px;
}

.futureCalendar .rdrMonthAndYearPickers {
    font-size: 22px;
}

.futureCalendar .rdrPprevButton i {
    border-width: 8px 12px 8px 0px;
    transform: translate(-7px, -8px);
}

.futureCalendar .rdrNextButton i {
    border-width: 8px 8px 8px 12px;
    transform: translate(-12px, -8px);
}

.futureCalendar .rdrDayToday .rdrDayNumber span:after {
    background-color: #56296f;
}

.futureCalendar .invoiceCounter {
    background: rgb(242, 112, 82);
    color: white;
    position: absolute;
    font-size: 14px;
    right: 0.5vw;
    top: 0.5vh;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30px;
    height: 30px;
    padding: 0 6px;
    text-align: center;
}